import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { createSlice } from '@reduxjs/toolkit'

export const locationSlice = createSlice({
  name: 'location',
  initialState: {},
  reducers: {
    save: {
      prepare({ payload, meta }) {
        return { payload, meta }
      },
      reducer(state, action) {
        const { payload, meta } = action
        const { key, field } = meta

        state[key] = {
          ...state?.[key],
          [field]: payload
        }

        return state
      }
    }
  }
})

export const { save } = locationSlice.actions

export const useLocationState = (field, defaultState = null) => {
  const { key, state } = useLocation()
  const reduxState = useSelector(state => state.location?.[key]?.[field] ?? null)
  const locationState = state?.[field] ?? null
  const dispatch = useDispatch()

  // Initial state comes from Redux, if that doesn't exist the state stored on location, or if neither then the default state if it was passed in
  const [localState, setLocalState] = useState(() => reduxState || locationState || defaultState)

  const setStateWrapped = useCallback(
    payload => {
      dispatch(save({ payload, meta: { field, key } }))
      setLocalState(payload)
    },
    [setLocalState, field, key, dispatch]
  )

  return [localState, setStateWrapped]
}

export const useQueryParams = () => {
  const { search } = useLocation()
  return new URLSearchParams(search)
}

export default locationSlice.reducer
