import { SimpleResource } from 'rest-hooks'

class NetworkError extends Error {
  status = null
  response = null

  constructor(response) {
    super(response.statusText)
    this.status = response.status
    this.response = response
  }
}

/**
 * Represents an entity to be retrieved from a server.
 * Typically 1:1 with a url endpoint.
 */
export default class RequestResource extends SimpleResource {
  /** A function to mutate all request options for fetch */
  static fetchOptionsPlugin = options => options

  /** Perform network request and resolve with HTTP Response */
  static fetchResponse(method, url, body) {
    let options = {
      method: method.toUpperCase(),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    if (body) options.body = JSON.stringify(body)
    if (this.fetchOptionsPlugin) options = this.fetchOptionsPlugin(options)

    return fetch(url, options)
      .then(response => {
        if (!response.ok) {
          throw new NetworkError(response)
        }
        return response
      })
      .catch(error => {
        // ensure CORS, network down, and parse errors are still caught by NetworkErrorBoundary
        if (error instanceof TypeError) {
          error.status = 400
        }
        throw error
      })
  }

  /** Perform network request and resolve with json body */
  static fetch(method, url, body) {
    return this.fetchResponse(method, url, body).then(response => {
      if (!response.headers.get('content-type')?.includes('json') || response.status === 204) return response.text()
      return response.json().catch(error => {
        error.status = 400
        throw error
      })
    })
  }
}
