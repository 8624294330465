import React from 'react'
import { Link } from 'react-router-dom'
import { PropTypes } from 'prop-types'

const divider = <p className='bread-crumbs__divider bread-crumbs__plain'>/</p>

const BreadCrumbs = ({ crumbs = [] }) => {
  const renderCrumb = (c, index) => {
    let crumb = c.path ? <Link to={{ pathname: c.path, state: c.state ?? {} }}>{c.label}</Link> : <p className='bread-crumbs__plain'>{c.label}</p>
    return (
      <span key={`${c.label}-${index}`} className='crumb__wrapper'>
        {crumb}
        {index < crumbs.length - 1 && divider}
      </span>
    )
  }

  return <div className='bread-crumbs__wrapper'>{crumbs.map(renderCrumb)}</div>
}

BreadCrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      label: PropTypes.string,
      state: PropTypes.object
    })
  )
}

export default BreadCrumbs
