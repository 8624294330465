import React, { useCallback, useState } from 'react'
import { ActionList, Popover } from '@shopify/polaris'
import Icon from './Icon'

const DataEditButton = ({ items }) => {
  const [active, setActive] = useState(false)
  const activate = useCallback(() => setActive(true), [])
  const deactivate = useCallback(() => setActive(false), [])

  const activator = <Icon onClick={activate} name='VERTICAL_DOTS' />

  return (
    <div className='data-edit-button__wrapper'>
      <Popover active={active} activator={activator} onClose={deactivate}>
        <ActionList items={items} onActionAnyItem={deactivate} />
      </Popover>
    </div>
  )
}

export default DataEditButton
