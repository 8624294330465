import stringify from 'json-stable-stringify'

import { findCookie } from '../utils/cookies'

import RequestResource from './RequestResource'

export default class LumifyResource extends RequestResource {
  static fetchOptionsPlugin = options => {
    const token = findCookie('CSRF-TOKEN')
    return {
      ...options,
      headers: {
        'X-CSRF-TOKEN': token,
        ...options.headers
      }
    }
  }

  static listShape() {
    const url = () => `${this.urlRoot}/list`
    const getFetchKey = params => {
      if (Object.keys(params ?? {}).length) {
        return `${url()}?${stringify(params)}`
      }
      return url()
    }
    const options = this.getFetchOptions()
    const fetch = params => this.fetch('post', url(), params)
    return {
      type: 'read',
      schema: { total: 0, rows: [this] },
      options,
      getFetchKey,
      fetch
    }
  }

  static createShape() {
    const getFetchKey = params => {
      if (Object.keys(params ?? {}).length) {
        return `${this.urlRoot}/create?${stringify(params)}`
      }
      return `${this.urlRoot}/create`
    }
    const options = this.getFetchOptions()
    const fetch = (params, body) => this.fetch('post', `${this.urlRoot}/create`, body)
    return {
      type: 'mutate',
      schema: this,
      options,
      getFetchKey,
      fetch
    }
  }

  static detailShape() {
    const url = params => `${this.urlRoot}/get/${this.pk(params)}`
    const getFetchKey = params => url(params)
    const options = this.getFetchOptions()
    const fetch = params => this.fetch('get', url(params))
    return {
      type: 'read',
      schema: this,
      options,
      getFetchKey,
      fetch
    }
  }

  static updateShape() {
    const url = params => `${this.urlRoot}/edit/${this.pk(params)}`
    const getFetchKey = params => url(params)
    const options = this.getFetchOptions()
    const fetch = (params, body) => this.fetch('put', url(params), body)
    return {
      type: 'mutate',
      schema: this,
      options,
      getFetchKey,
      fetch
    }
  }

  static deleteShape() {
    const url = params => `${this.urlRoot}/delete/${this.pk(params)}`
    const getFetchKey = params => url(params)
    const options = this.getFetchOptions()
    const fetch = params => this.fetch('delete', url(params))
    return {
      type: 'mutate',
      schema: this,
      options,
      getFetchKey,
      fetch
    }
  }

  static countShape() {
    const url = () => `${this.urlRoot}/list`
    const getFetchKey = () => `${url()}/count`
    const options = this.getFetchOptions()
    const baseParams = {
      offset: 0,
      limit: 1,
      order: 'desc',
      sort: 'created_date'
    }
    const fetch = params => this.fetch('post', url(), { ...baseParams, ...params })
    return {
      type: 'read',
      schema: { total: 0, rows: [] },
      options,
      getFetchKey,
      fetch
    }
  }
}
