import React from 'react'
import PropTypes from 'prop-types'
import BreadCrumbs from './BreadCrumbs'
import SecondaryAction from './SecondaryAction'

const View = ({ title, subtitle, secondaryActions, breadcrumbs, children }) => {
  const renderBreadCrumbs = () => {
    if ((breadcrumbs ?? []).length) {
      return (
        <div className='view__breadcrumbs'>
          <BreadCrumbs crumbs={breadcrumbs} />
        </div>
      )
    }
  }

  const renderTitle = () => {
    if (Boolean(title)) {
      return <h1 className='view__title'>{title}</h1>
    }
  }

  const renderSubTitle = () => {
    if (Boolean(subtitle)) {
      return <h2 className='view__subtitle'>{subtitle}</h2>
    }
  }

  const renderSecondaryActions = () => {
    if ((secondaryActions ?? []).length) {
      return (
        <div className='view__secondary-actions'>
          {secondaryActions.map((a, i) => (
            <SecondaryAction key={`${a.content}-${i}`} {...a} />
          ))}
        </div>
      )
    }
  }

  return (
    <div className='view view__wrapper'>
      <div className='view__header'>
        {renderBreadCrumbs()}
        {renderTitle()}
        {renderSubTitle()}
        {renderSecondaryActions()}
      </div>
      <div className='view__content'>{children}</div>
    </div>
  )
}

View.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  secondaryActions: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      icon: PropTypes.element,
      onAction: PropTypes.func,
      visible: PropTypes.bool
    })
  ),
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string
    })
  )
}

View.defaultProps = {
  title: undefined,
  subtitle: undefined,
  secondaryActions: [],
  breadcrumbs: [],
  pagination: undefined
}

export default View
