import React, { useCallback, useState } from 'react'
import { Modal } from '@shopify/polaris'
import moment from 'moment'
import { useResource } from 'rest-hooks'

import CodeBlock from '../../components/CodeBlock'
import DataEditButton from '../../components/DataEditButton'
import DataTable from '../../components/DataTable'
import Icon from '../../components/Icon'
import Pagination from '../../components/Pagination'
import { itemsPerPageOptions } from '../../constants/Pagination'
import JournalResource from '../../resources/JournalResource'
import { dateIntakeFormat } from '../../utils/date'
import { useLocationState } from '../../utils/location'

export const journalTableColumnData = [
  { type: 'text', label: 'Date Created', sortProperty: 'created_date' },
  { type: 'text', label: 'Source', sortProperty: 'event_source' },
  { type: 'text', label: 'Type', sortProperty: 'event_type' },
  { type: 'numeic', label: '' }
]

const JournalTable = ({ dateFilter, typeFilter }) => {
  const [queryParams, setQueryParams] = useLocationState('query-params', {
    sort: 'created_date',
    order: 'asc',
    offset: 0,
    limit: itemsPerPageOptions[0].value,
    start_date: dateFilter?.start,
    end_date: dateFilter?.end,
    journal_types: typeFilter
  })

  const [showPayloadModal, setShowPayloadModal] = useState(false)
  const [payloadForDisplay, setPayloadForDisplay] = useState()

  const journalEntries = useResource(JournalResource.listShape(), {
    ...queryParams,
    ...{
      start_date: dateFilter?.start ? moment(dateFilter.start).format('MM/DD/YYYY') : null,
      end_date: dateFilter?.end ? moment(dateFilter.end).format('MM/DD/YYYY') : null,
      journal_types: typeFilter
    }
  })

  const rowData = (journalEntries.rows ?? []).map(entry => {
    return [
      moment(entry.created_date, dateIntakeFormat).format('D-MMM-YYYY'),
      entry.event_source,
      entry.event_type,
      <DataEditButton
        items={[
          {
            content: 'View Message',
            icon: () => <Icon name='CODE' />,
            onAction: () => {
              setPayloadForDisplay(entry.payload)
              setShowPayloadModal(true)
            }
          },
          {
            content: 'Copy Message Text',
            icon: () => <Icon name='COPY' />,
            onAction: () => navigator.clipboard.writeText(JSON.stringify(entry.payload))
          }
        ]}
      />
    ]
  })

  const onPagination = useCallback(
    pg => {
      setQueryParams({ ...queryParams, ...pg })
    },
    [queryParams, setQueryParams]
  )

  const onSort = useCallback(
    async (index, order) => {
      setQueryParams({ ...queryParams, ...{ sort: journalTableColumnData[index].sortProperty, order: order === 'ascending' ? 'asc' : 'desc' } })
    },
    [queryParams, setQueryParams]
  )

  return (
    <>
      <DataTable
        columnContentTypes={journalTableColumnData.map(c => c.type)}
        headings={journalTableColumnData.map(c => c.label)}
        rows={rowData}
        hideScrollIndicator
        sortable={journalTableColumnData.map(c => Boolean(c.sortProperty))}
        initialSortColumnIndex={journalTableColumnData.findIndex(c => c.sortProperty === queryParams.sort)}
        defaultSortDirection={queryParams.order === 'asc' ? 'ascending' : 'descending'}
        onSort={onSort}
        footerContent={<Pagination onChange={onPagination} total={journalEntries.total} limit={queryParams.limit} offset={queryParams.offset} />}
      />
      <JournalPayloadModal show={showPayloadModal} payload={payloadForDisplay} onClose={() => setShowPayloadModal(false)} />
    </>
  )
}

const JournalPayloadModal = ({ show, payload, onClose }) => {
  return (
    <Modal large onClose={onClose} open={show} title='Message Payload'>
      <CodeBlock data={payload} />
      <Modal.Section></Modal.Section>
    </Modal>
  )
}

export default JournalTable
