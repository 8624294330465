import React from 'react'
import { DatePicker, Button } from '@shopify/polaris'

const DatePickerFilter = props => {
  return (
    <div className='date-picker-filter'>
      <DatePicker {...props} />
      <div className='button-wrap'>
        <Button size='slim' onClick={() => props.onChange(undefined)}>
          Clear Selection
        </Button>
      </div>
    </div>
  )
}

export default DatePickerFilter
