import moment from 'moment'

import LumifyResource from './LumifyResource'
export const SAML_URL = '/saml/login'
export const AUTH_INFO = '/admin/secured/userInformation'
export class AuthInfo extends LumifyResource {
  first_name = ''
  last_name = ''
  email = ''
  role = 'ROLE_NONE'

  pk() {
    return AUTH_INFO
  }

  static authInfoShape() {
    return {
      type: 'read',
      schema: this,
      getFetchKey: () => AUTH_INFO,
      options: { invalidIfStale: true },
      fetch: async () => {
        try {
          return await this.fetch('get', AUTH_INFO)
        } catch (err) {
          if (err.status === 403) {
            return {
              first_name: '',
              last_name: '',
              email: '',
              role: 'ROLE_NONE'
            }
          } else {
            throw err
          }
        }
      }
    }
  }

  static get key() {
    return AUTH_INFO
  }
  static url = () => AUTH_INFO
}

export const AUTH_LOGOUT = '/admin/secured/logoff'
export class AuthLogout extends LumifyResource {
  static logoutShape() {
    return {
      type: 'read',
      schema: false,
      getFetchKey: () => AUTH_LOGOUT,
      options: {},
      fetch: () => this.fetch('get', AUTH_LOGOUT)
    }
  }

  pk() {
    return AUTH_LOGOUT
  }

  static get key() {
    return AUTH_LOGOUT
  }
  static url = () => AUTH_LOGOUT
}

export const CHECK_LOGIN = '/admin/secured/userLoggedIn'
export class CheckLogin extends LumifyResource {
  expires = moment().add(-15, 'seconds').unix()

  static fetchOptionsPlugin = options => ({
    ...options,
    headers: {
      'Content-Type': 'application/json'
    }
  })

  static checkLoginShape() {
    return {
      ...this.detailShape(),
      getFetchKey: () => CHECK_LOGIN,
      fetch: async () => {
        const response = await fetch(CHECK_LOGIN, this.fetchOptionsPlugin({}))
        const text = await response.text()
        return {
          expires: moment().add(text, 'seconds').add(-15, 'seconds').unix()
        }
      },
      options: {
        invalidIfStale: true,
        pollFrequency: 300000 // every 5 minutes
      }
    }
  }

  pk() {
    return CHECK_LOGIN
  }

  static urlRoot = CHECK_LOGIN
}

export const FORCE_LOGIN = '/admin/secured/logUser'
export class ForceLogin extends LumifyResource {
  static forceLoginShape() {
    return {
      ...this.detailShape(),
      schema: '',
      getFetchKey: () => FORCE_LOGIN,
      fetch: role => {
        return this.fetch('get', `${FORCE_LOGIN}?role=${role}`)
      }
    }
  }

  pk() {
    return FORCE_LOGIN
  }

  static urlRoot = FORCE_LOGIN
}

export const CHECK_PRODUCTION = '/admin/secured/isProduction'
export class CheckProduction extends LumifyResource {
  static checkProductionShape() {
    return {
      type: 'read',
      schema: '',
      getFetchKey: () => CHECK_PRODUCTION,
      options: {},
      fetch: () => this.fetch('get', CHECK_PRODUCTION)
    }
  }

  pk() {
    return CHECK_PRODUCTION
  }

  static urlRoot = CHECK_PRODUCTION
}
