import LumifyResource from './LumifyResource'

const baseUrl = '/admin/journal/api/v2/journal_types'

class JournalTypeResource extends LumifyResource {
  pk() {
    return 1
  }

  static listShape() {
    const url = () => this.urlRoot
    const getFetchKey = url
    const options = this.getFetchOptions()
    const fetch = () => this.fetch('get', url())

    return {
      type: 'read',
      schema: [],
      options,
      getFetchKey,
      fetch
    }
  }

  static urlRoot = baseUrl
}

export default JournalTypeResource
