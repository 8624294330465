import React, { useState } from 'react'
import { Button, ButtonGroup, OptionList, Popover, TextStyle } from '@shopify/polaris'
import PropTypes from 'prop-types'
import { itemsPerPageOptions } from '../constants/Pagination'
import Icon from './Icon'
import { useEffect } from 'react'
import { useCallback } from 'react'

const Pagination = ({ offset, limit, total = 0, onChange }) => {
  const [itemsPerPagePopoverVisible, setItemsPerPagePopoverVisible] = useState(false)

  const currentPage = Math.floor(offset / limit)
  const start = offset + 1
  const end = start + limit - 1
  const numPages = Math.ceil(total / limit)

  const onChangeLimit = num => {
    onChange({ offset: 0, limit: num })
    window.scrollTo(0, 0)
  }

  const onChangePage = useCallback(
    page => {
      onChange({ offset: page * limit, limit })
      window.scrollTo(0, 0)
    },
    [onChange, limit]
  )

  useEffect(() => {
    if (numPages > 0 && currentPage > numPages - 1) {
      onChangePage(numPages - 1)
    }
  }, [currentPage, numPages, onChangePage])

  const renderPageButtons = () => {
    const total = numPages < 5 ? numPages : 5
    const pages = []
    const currentPageIndex = currentPage >= 2 && currentPage < numPages - 2 ? 2 : currentPage

    for (let i = 0; i < total; i++) {
      let page = (i === currentPageIndex ? currentPage : i) + 1
      switch (i) {
        case 1:
          page = currentPage >= 2 && total === 5 ? '...' : i + 1
          break
        case 2:
          if (currentPage >= numPages - 3 && total === 5) {
            page = numPages - 2
          }
          break
        case 3:
          if (currentPage >= numPages - 3 && total === 5) {
            page = numPages - 1
            break
          }
          page = currentPageIndex <= numPages - 2 && total === 5 ? '...' : page
          break
        case 4:
          page = numPages
          break
        default:
          break
      }

      pages.push(
        <span key={page} className={`paginaton-button__wrapper${typeof page === 'number' && page - 1 === currentPage ? ' active' : ''}`}>
          <Button disabled={typeof page === 'string'} onClick={() => onChangePage(page - 1)}>
            {page}
          </Button>
        </span>
      )
    }
    return pages
  }

  const activator = (
    <Button onClick={() => setItemsPerPagePopoverVisible(!itemsPerPagePopoverVisible)} disclosure>
      <TextStyle variation='subdued'>Records per page</TextStyle> {limit}
    </Button>
  )

  const handleChangeLimit = async selected => {
    await setItemsPerPagePopoverVisible(false)
    onChangeLimit(selected[0])
  }

  if (total <= 0) return null

  return (
    <div className='pagination__wrap'>
      <div className='pagination__section'>
        <span className='summary'>{`${start}-${end > total ? total : end} of ${total}`}</span>
        {total > itemsPerPageOptions[0].value ? (
          <Popover active={itemsPerPagePopoverVisible} activator={activator} onClose={() => setItemsPerPagePopoverVisible(false)}>
            <OptionList options={itemsPerPageOptions} onChange={handleChangeLimit} selected={[limit] ?? []} />
          </Popover>
        ) : null}
      </div>
      {total > limit ? (
        <div className='pagination__section'>
          <ButtonGroup segmented>
            <Button
              onClick={() => onChangePage(currentPage - 1)}
              disabled={currentPage === 0}
              icon={<Icon className='button-previous' height={22} width={22} name='DROPDOWN' />}
            />
            {renderPageButtons()}
            <Button
              onClick={() => onChangePage(currentPage + 1)}
              disabled={currentPage + 1 === numPages}
              icon={<Icon className='button-next' height={22} width={22} name='DROPDOWN' />}
            />
          </ButtonGroup>
        </div>
      ) : null}
    </div>
  )
}

Pagination.defaultProps = {
  currentPage: 0,
  limit: itemsPerPageOptions[0].value,
  total: 0
}

Pagination.propTypes = {
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Pagination
