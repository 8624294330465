import React from 'react'

import './CodeBlock.css'

const CodeBlock = ({ data, indent = 2 }) => {
  if (typeof data === 'string') {
    try {
      data = JSON.parse(data)
    } catch (error) {}
  }
  if (Array.isArray(data) || typeof data == 'object') {
    data = JSON.stringify(data, null, indent)
  }
  return (
    <div className='code-block__wrapper'>
      <pre className='code-block'>{data}</pre>
    </div>
  )
}

export default CodeBlock
