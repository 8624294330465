import LumifyResource from './LumifyResource'

const baseUrl = '/admin/journal/api/v2'

class JournalResource extends LumifyResource {
  id = null
  created_date = null
  event_source = null
  event_type = null
  payload = null
  meta = null

  pk() {
    return this.id
  }

  static urlRoot = baseUrl
}

export default JournalResource
