import React from 'react'
import PropTypes from 'prop-types'

const SecondaryAction = ({ content, icon, onAction, visible = true }) => {
  if (!visible) return null

  return (
    <button onClick={onAction} className='button button__secondary'>
      {Boolean(icon) && icon}
      {content}
    </button>
  )
}

SecondaryAction.propTypes = {
  content: PropTypes.string,
  icon: PropTypes.element,
  onAction: PropTypes.func,
  visible: PropTypes.bool
}

export default SecondaryAction
