import produce from 'immer'

export const ROLE_ADMIN = 'ROLE_PHILIPS_ADMIN'
export const ROLE_SALES_MANAGER = 'ROLE_PHILIPS_SALES_MANAGER'
export const ROLE_SALES = 'ROLE_PHILIPS_SALES'
export const ROLE_USER = 'ROLE_PHILIPS_USER'
export const ROLE_NONE = 'ROLE_NONE'

export const ROLE_NAME_LOOKUP = {
  [ROLE_ADMIN]: 'Admin',
  [ROLE_SALES_MANAGER]: 'Sales Manager',
  [ROLE_SALES]: 'Sales',
  [ROLE_USER]: 'View Only'
}

export const getRoleById = id => {
  switch (id) {
    case 1:
      return ROLE_ADMIN
    case 2:
      return ROLE_SALES_MANAGER
    case 3:
      return ROLE_SALES
    case 4:
      return ROLE_USER
    default:
      return ROLE_NONE
  }
}

export default produce({}, draft => {
  draft.ROLE_ADMIN = ROLE_ADMIN
  draft.ROLE_SALES_MANAGER = ROLE_SALES_MANAGER
  draft.ROLE_SALES = ROLE_SALES
  draft.ROLE_USER = ROLE_USER
  draft.ROLE_NONE = ROLE_NONE
})
