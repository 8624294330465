import React from 'react'
import Journal from '../features/Journal/Journal'

import { ROLE_ADMIN } from './roles'

const Dashboard = React.lazy(() => import('../features/Dashboard'))
const DeviceHistory = React.lazy(() => import('../features/DeviceHistory/DeviceHistory'))
const Customers = React.lazy(() => import('../features/Customer/Customers'))
const CustomerCreate = React.lazy(() => import('../features/Customer/CustomerCreate'))
const CustomersSaved = React.lazy(() => import('../features/Customer/CustomersSaved'))
const CustomerDetails = React.lazy(() => import('../features/Customer/CustomerDetails'))
const CustomerContactCreate = React.lazy(() => import('../features/Customer/CustomerContactCreate'))
const Contacts = React.lazy(() => import('../features/Contact/Contacts'))
const ContactsSaved = React.lazy(() => import('../features/Contact/ContactsSaved'))
const ContactDetails = React.lazy(() => import('../features/Contact/ContactDetails'))
const Invoices = React.lazy(() => import('../features/Invoice/Invoices'))
const InvoiceDetail = React.lazy(() => import('../features/Invoice/InvoiceDetail'))
const NewOrder = React.lazy(() => import('../features/NewOrder/NewOrder'))
const NewOrderSummary = React.lazy(() => import('../features/NewOrder/NewOrderSummary'))
const Role = React.lazy(() => import('../features/Role/Role'))
const Orders = React.lazy(() => import('../features/Order/Orders'))
const OrdersSaved = React.lazy(() => import('../features/Order/OrdersSaved'))
const OrdersQuote = React.lazy(() => import('../features/Order/OrdersQuote'))
const OrderDetails = React.lazy(() => import('../features/Order/OrderDetail'))
const OrderDiagnostics = React.lazy(() => import('../features/Order/OrderDiagnostics'))
const OrderHistory = React.lazy(() => import('../features/Order/OrderHistory'))
const OrdersQuoteDetails = React.lazy(() => import('../features/Order/OrdersQuoteDetails'))
const ReleaseOrders = React.lazy(() => import('../features/Invoice/ReleaseOrders'))
const ReleaseOrderDetail = React.lazy(() => import('../features/Invoice/ReleaseOrderDetail'))
const SoftwarePlatform = React.lazy(() => import('../features/Software/SoftwarePlatform'))
const SoftwareVersionGroup = React.lazy(() => import('../features/Software/SoftwareVersionGroup'))
const Transducers = React.lazy(() => import('../features/Transducer/Transducer'))
const TransducerDetails = React.lazy(() => import('../features/Transducer/TransducerDetails'))
const TransducerHistory = React.lazy(() => import('../features/Transducer/TransducerHistory'))
const TransducersAvailable = React.lazy(() => import('../features/Transducer/TransducersAvailable'))
const TransducerGroups = React.lazy(() => import('../features/TransducerGroup/TransducerGroups'))
const TransducerGroupDetails = React.lazy(() => import('../features/TransducerGroup/TransducerGroupDetails'))
const UnitsAffected = React.lazy(() => import('../features/UnitsAffected/UnitsAffected'))

export const PARAM_SELECTORS = {
  id: ':id'
}

export const buildPathDetails = (route, id) => {
  return route?.path?.replace(PARAM_SELECTORS.id, String(id ?? '').trim())
}

export const ROUTES = {
  HOME: {
    path: '/home',
    component: Dashboard,
    label: 'Home',
    icon: 'HOME'
  },
  DHR: {
    path: '/dhr',
    component: DeviceHistory,
    label: 'DHR Report',
    icon: 'COMPASS'
  },
  CUSTOMER: {
    path: '/customer',
    component: Customers,
    label: 'Customers',
    icon: 'ACCOUNT'
  },
  CUSTOMER_CREATE: {
    path: '/customer/create',
    component: CustomerCreate,
    label: 'New'
  },
  CUSTOMER_SAVED: {
    path: '/customer/saved',
    component: CustomersSaved,
    label: 'Saved Customers'
  },
  CUSTOMER_DETAILS: {
    path: `/customer/${PARAM_SELECTORS.id}/details`,
    component: CustomerDetails
  },
  CUSTOMER_CONTACT_CREATE: {
    path: `/customer/${PARAM_SELECTORS.id}/create-contact`,
    component: CustomerContactCreate
  },
  CUSTOMER_CONTACT_NEWORDER: {
    path: `/customer/${PARAM_SELECTORS.id}/new-order`,
    component: NewOrder
  },
  CUSTOMER_CONTACT_NEWORDER_SUMMARY: {
    path: `/customer/${PARAM_SELECTORS.id}/new-order-summary`,
    component: NewOrderSummary
  },
  CONTACT: {
    path: '/contact',
    component: Contacts,
    label: 'Contacts',
    icon: 'PROFILE'
  },
  CONTACT_SAVED: {
    path: '/contact/saved',
    component: ContactsSaved,
    label: 'Saved Contacts'
  },
  CONTACT_DETAILS: {
    path: `/contact/${PARAM_SELECTORS.id}/details`,
    component: ContactDetails
  },
  ORDER: {
    path: '/order',
    component: Orders,
    label: 'Orders',
    icon: 'ORDERS'
  },
  ORDER_SAVED: {
    path: '/order/saved',
    component: OrdersSaved,
    label: 'Saved Orders'
  },
  ORDER_QUOTES: {
    path: '/order/quotes',
    component: OrdersQuote,
    label: 'Quotes'
  },
  ORDER_QUOTE_DETAILS: {
    path: '/order/quotes/:id/details',
    component: OrdersQuoteDetails
  },
  ORDER_DETAILS: {
    path: '/order/:id/details',
    component: OrderDetails
  },
  ORDER_HISTORY: {
    path: `/order/${PARAM_SELECTORS.id}/history`,
    component: OrderHistory
  },
  ORDER_DIAGNOSTICS: {
    path: '/order/:id/diagnostics',
    component: OrderDiagnostics
  },
  INVOICE: {
    path: '/invoice',
    component: Invoices,
    label: 'Invoices',
    icon: 'ORDERS_INVOICES'
  },
  INVOICE_DETAILS: {
    path: '/invoice/:id/details',
    component: InvoiceDetail
  },
  RELEASE_ORDERS: {
    path: '/invoice/release-orders',
    component: ReleaseOrders,
    label: 'Release Orders',
    roles: [ROLE_ADMIN]
  },
  RELEASE_ORDER_DETAIL: {
    path: '/invoice/release-orders/:id/details',
    component: ReleaseOrderDetail,
    roles: [ROLE_ADMIN]
  },
  PLATFORM: {
    path: '/platform',
    component: SoftwarePlatform,
    label: 'Software Platforms',
    icon: 'TABLET'
  },
  TRANSDUCER: {
    path: '/transducer',
    component: Transducers,
    label: 'Transducers',
    icon: 'XDUCER_NAV'
  },
  TRANSDUCER_DETAILS: {
    path: '/transducer/:id/details',
    component: TransducerDetails
  },
  TRANSDUCER_HISTORY: {
    path: '/transducer/:id/history',
    component: TransducerHistory
  },
  TRANSDUCER_AVAILABLE: {
    path: '/transducer/available',
    component: TransducersAvailable,
    roles: [ROLE_ADMIN],
    label: 'Available'
  },
  SOFTWARE_VERSION_LIST: {
    path: '/software_versions/:id/list',
    component: SoftwareVersionGroup
  },
  GROUP: {
    path: '/group',
    component: TransducerGroups,
    roles: [ROLE_ADMIN],
    label: 'Groups',
    icon: 'CONNECTIONS'
  },
  GROUP_DETAILS: {
    path: '/group/:id/details',
    component: TransducerGroupDetails,
    roles: [ROLE_ADMIN],
    label: 'Details'
  },
  LUMIFY_UAL: {
    path: '/units-affected',
    component: UnitsAffected,
    roles: [ROLE_ADMIN],
    label: 'UAL',
    icon: 'PUZZLE'
  },
  JOURNAL: {
    path: '/journal',
    component: Journal,
    roles: [ROLE_ADMIN],
    label: 'Journal',
    icon: 'WRENCH'
  },
  ROLE: {
    path: '/role',
    component: Role,
    roles: [ROLE_ADMIN],
    label: 'Roles & Permissions',
    icon: 'SETTINGS'
  }
}
