import moment from 'moment'
import { useInvalidator, useResource } from 'rest-hooks'

import { ROLE_NONE } from '../constants/roles'
import { AuthInfo, CheckLogin } from '../resources/auth'
import { findCookie } from '../utils/cookies'

export const useAuthInfo = () => {
  const info = useResource(AuthInfo.authInfoShape(), {})
  const invalidateLogin = useInvalidator(CheckLogin.checkLoginShape())

  if (info.role === ROLE_NONE) {
    console.log('Role is invalid so invalidating login')
    invalidateLogin({})
  }

  return info
}

export const useCheckLogin = () => {
  const result = useResource(CheckLogin.checkLoginShape(), {})
  return result.expires > moment().unix()
}

export const useIsAuthorized = () => {
  const auth = findCookie('UNAUTHORIZED')
  return parseInt(auth) !== -1
}
