import { Modal } from '@shopify/polaris';
import React from 'react';
import './UnsavedChangesModal.css';

const UnsavedChangesModal = ({ confirm, cancel, open }) => {
    return (
        <Modal
            open={open}
            onClose={cancel}
            title='Unsaved Changes'
            primaryAction={{ content: 'Continue without Saving', onAction: confirm }}
            secondaryActions={[{ content: 'Cancel', onAction: cancel }]}
        >
            <Modal.Section>
                <div className='unsaved-changes-modal__body'>
                    <p className='message'>Leaving this page now will abandon your changes. Are you sure you want to proceed?</p>
                </div>
            </Modal.Section>
        </Modal>
    )
}

export default UnsavedChangesModal;
