import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { NetworkManager, PollingSubscription, PromiseifyMiddleware, reducer as restHooks, SubscriptionManager } from 'rest-hooks'

import location from '../utils/location'

const manager = new NetworkManager()
const subscriptionManager = new SubscriptionManager(PollingSubscription)

const store = configureStore({
  reducer: {
    restHooks,
    location
  },
  middleware: [
    manager.getMiddleware(),
    subscriptionManager.getMiddleware(),
    PromiseifyMiddleware,
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    })
  ]
})

export default store
