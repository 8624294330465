import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppProvider } from '@shopify/polaris'
import enTranslations from '@shopify/polaris/locales/en.json'

import PhilipsShield from '../assets/images/PhilipsShield.svg'

import Routes from './Routes'
import store from './store'
import LoadingPage from '../components/LoadingPage'
import './App.css'

const theme = {
  colors: {
    topBar: {
      background: 'var(--blue5)',
      backgroundLighter: 'RGBA(255,255,255, 0.18)',
      backgroundDarker: 'RGBA(0,0,0,0.18)',
      border: 'var(--gray2)',
      color: 'var(--white)'
    }
  },
  logo: {
    width: 32,
    topBarSource: PhilipsShield,
    url: '',
    accessibilityLabel: 'Philips'
  }
}

const ReactRouterLink = ({ children, url = '', ...rest }) => (
  <Link to={url} {...rest}>
    {children}
  </Link>
)

const App = () => (
  <Provider store={store}>
    <AppProvider theme={theme} i18n={enTranslations} linkComponent={ReactRouterLink}>
      <div className='app'>
        <Suspense fallback={<LoadingPage />}>
          <Routes />
        </Suspense>
      </div>
    </AppProvider>
  </Provider>
)

export default App
