import React from 'react'
import ReactDOM from 'react-dom'
import { AppProvider } from '@shopify/polaris'
import enTranslations from '@shopify/polaris/locales/en.json'
import { ExternalCacheProvider } from 'rest-hooks'

import App from './app/App'
import store from './app/store'
import * as serviceWorker from './serviceWorker'

// IMPORTANT! this import must come first
import '@shopify/polaris/styles.css'
import './polaris-overrides.css'
import './index.css'
import './components/components.css'
import './features/features.css'

ReactDOM.render(
  <AppProvider i18n={enTranslations}>
    <ExternalCacheProvider store={store} selector={state => state.restHooks}>
      <App />
    </ExternalCacheProvider>
  </AppProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()
