import { Spinner } from '@shopify/polaris'
import React from 'react'
import './LoadingPage.css'

const LoadingPage = () => {
  return (
    <div className='loading-indicator__wrapper'>
      <Spinner size='large' color='inkLightest' />
    </div>
  )
}

export default LoadingPage
