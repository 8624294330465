import moment from 'moment'

export const formatDateRangeDisplayText = (dateRange = null, placeholder = 'Select a date range') => {
  if (!Boolean(dateRange)) {
    return placeholder
  }

  const format = 'MMM D, YYYY'
  const formattedStart = moment(dateRange.start).format(format)
  const formattedEnd = moment(dateRange.end).isSame(moment(dateRange.start)) ? '' : ` - ${moment(dateRange.end).format(format)}`

  return `${formattedStart}${formattedEnd}`
}
