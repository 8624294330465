import React from 'react'
import { SkeletonBodyText } from '@shopify/polaris'
import PropTypes from 'prop-types'
import DataTable from './DataTable'

const SkeletonTable = ({ sort, order, headings = [], limit = 2 }) => {
  const rows = []
  for (let i = 0; i < limit; i++) {
    rows.push(headings.map(h => <SkeletonBodyText lines={1} />))
  }

  return (
    <>
      <DataTable
        columnContentTypes={headings.map(h => h.type)}
        headings={headings.map(h => h.label)}
        sortable={headings.map(d => Boolean(d.sortProperty))}
        defaultSortDirection={order}
        hideScrollIndicator
        initialSortColumnIndex={Boolean(sort) && headings.findIndex(c => c.sortProperty === sort)}
        rows={rows}
      />
    </>
  )
}

SkeletonTable.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      sortProperty: PropTypes.string
    })
  ).isRequired
}

SkeletonTable.defaultProps = {
  headings: []
}

export default SkeletonTable
