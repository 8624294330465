import React from 'react'
import PropTypes from 'prop-types'
import { DataTable as PolarisTable } from '@shopify/polaris'

const renderEmptyRows = (emptyTitle, emptySubtitle, emptyNumRows = 5, headings = []) => {
  const titleIndex = emptyNumRows >= 3 ? 1 : 0
  const subtitleIndex = titleIndex + 1
  const rows = []
  for (let i = 0; i < emptyNumRows; i++) {
    const r = headings.map((h, idx) => {
      if (idx === 0 && (i === titleIndex || i === subtitleIndex)) {
        if (i === titleIndex) {
          return <h3 className='empty empty-title'>{emptyTitle}</h3>
        }
        if (i === subtitleIndex) {
          return <p className='empty empty-subtitle'>{emptySubtitle}</p>
        }
        return <span />
      }
      return <span />
    })
    rows.push(r)
  }
  return rows
}

const DataTable = ({ emptyTitle, emptySubtitle, emptyNumRows, rows, ...rest }) => {
  const isEmpty = (rows ?? []).length <= 0
  const footer = isEmpty ? null : rest.footerContent
  const r = !isEmpty ? rows : renderEmptyRows(emptyTitle, emptySubtitle, emptyNumRows, rest.headings)

  return (
    <div className={`data-table__wrapper${!isEmpty ? ' filled' : ''}`}>
      <PolarisTable {...rest} rows={r} hideScrollIndicator footerContent={footer} />
      {isEmpty && renderEmptyRows({ emptyTitle, emptySubtitle, emptyNumRows })}
    </div>
  )
}

DataTable.propTypes = {
  emptyTitle: PropTypes.string,
  emptySubtitle: PropTypes.string,
  emptyNumRows: PropTypes.number
}

DataTable.defaultProps = {
  emptyTitle: 'There isn’t any data here.',
  emptySubtitle: 'When there is, you’ll see it in this table.',
  emptyNumRows: 5
}

export default DataTable
