import React, { Suspense, useCallback, useState } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Frame } from '@shopify/polaris'
import { NetworkErrorBoundary, useSubscription } from 'rest-hooks'

import LoadingPage from '../components/LoadingPage'
import UnsavedChangesModal from '../components/UnsavedChangesModal'
import { ROUTES } from '../constants/navigation'
import { CheckLogin } from '../resources/auth'
import { useAuthInfo, useCheckLogin } from '../utils/auth'

const TopBar = React.lazy(() => import('./Header'))
const SideNav = React.lazy(() => import('./SideNav'))
const Landing = React.lazy(() => import('../features/Landing/Landing'))
const ErrorPage = React.lazy(() => import('../features/Error/ErrorPage'))

const LoggedInRoutes = () => {
  useSubscription(CheckLogin.checkLoginShape(), {})
  const { role } = useAuthInfo()
  const { routes, noPermission } = Object.values(ROUTES).reduce(
    (acc, curr) => {
      const hasPermission = (curr.roles ?? []).length ? curr.roles.some(r => r === role) : true
      if (hasPermission) {
        acc.routes.push(curr)
      } else {
        acc.noPermission.push(curr)
      }
      return acc
    },
    { routes: [], noPermission: [] }
  )

  return (
    <Frame topBar={<TopBar />} navigation={<SideNav />}>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          {routes.map(({ icon, label, component: Component, ...rest }) => (
            <Route key={rest.path} exact {...rest}>
              <NetworkErrorBoundary fallbackComponent={err => <ErrorPage error={err.error} />}>
                <Component />
              </NetworkErrorBoundary>
            </Route>
          ))}
          {noPermission.map(r => (
            <Route key={r.path} exact path={r.path} render={() => <ErrorPage error={{ status: 403 }} />} />
          ))}
          <Redirect from='/' to='/home' />
          <Route exact path='*' render={() => <ErrorPage error={{ status: 404 }} />} />
        </Switch>
      </Suspense>
    </Frame>
  )
}

const LoggedOutRoutes = () => {
  return (
    <Switch>
      <Route exact path='/'>
        <Landing />
      </Route>
      <Redirect from='*' to='/' />
    </Switch>
  )
}

const Routes = () => {
  const loggedIn = useCheckLogin()
  const [callback, setCallback] = useState(null)

  const handleAction = useCallback(
    confirmed => {
      callback(confirmed)
      setCallback(null)
    },
    [callback]
  )

  const handleGetConfirmation = (_, callback) => setCallback(() => callback)
  const handleConfirm = () => handleAction(true)
  const handleCancel = () => handleAction(false)

  return (
    <BrowserRouter getUserConfirmation={handleGetConfirmation}>
      {loggedIn ? <LoggedInRoutes /> : <LoggedOutRoutes />}
      <UnsavedChangesModal open={callback !== null} confirm={handleConfirm} cancel={handleCancel} />
    </BrowserRouter>
  )
}

export default Routes
