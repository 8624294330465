import React, { Suspense, useState } from 'react'
import { useResource } from 'rest-hooks'
import { Card, OptionList, Tag, Popover, Button } from '@shopify/polaris'

import moment from 'moment'

import View from '../../components/View'
import { formatDateRangeDisplayText } from '../../utils/dateRange'
import { useLocationState } from '../../utils/location'
import DatePickerFilter from '../../components/DatePickerFilter'
import SkeletonTable from '../../components/SkeletonTable'
import JournalTable, { journalTableColumnData } from './JournalTable'
import JournalTypeResource from '../../resources/JournalTypeResource'

const Journal = () => {
  const [selectedDates, setSelectedDates] = useLocationState('selected-dates', { month: moment().month(), year: moment().year() })
  const [filters, setFilters] = useLocationState('filters', null)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [showTypePicker, setShowTypePicker] = useState(false)

  const journalTypes = useResource(JournalTypeResource.listShape(), {})

  const appliedFilters = filters?.typeFilter?.map(o => ({
    label: o,
    onRemove: () => {
      setFilters({ ...filters, typeFilter: filters.typeFilter.filter(value => value !== o) })
    }
  }))

  return (
    <View title='Journal'>
      <Card>
        <Card.Section>
          <div className='applied-filters__wrap'>
            <Popover
              active={showDatePicker}
              onClose={() => setShowDatePicker(false)}
              activator={
                <Button disclosure onClick={() => setShowDatePicker(true)}>
                  {formatDateRangeDisplayText(filters?.dateFilter)}
                </Button>
              }
            >
              <div className='popover__inner-wrap'>
                <DatePickerFilter
                  month={selectedDates.month}
                  year={selectedDates.year}
                  multiMonth
                  allowRange
                  onMonthChange={(m, y) => setSelectedDates({ month: m, year: y })}
                  selected={filters?.dateFilter}
                  onChange={date => setFilters({ ...filters, dateFilter: date })}
                />
              </div>
            </Popover>
            <Popover
              active={showTypePicker}
              onClose={() => setShowTypePicker(false)}
              activator={
                <Button disclosure onClick={() => setShowTypePicker(true)}>
                  Type
                </Button>
              }
            >
              <div className='popover__inner-wrap'>
                <OptionList
                  title='Type'
                  options={journalTypes?.map(t => ({ label: t, value: t }))}
                  selected={filters?.typeFilter ?? []}
                  allowMultiple
                  onChange={opt => setFilters({ ...filters, typeFilter: opt })}
                />
              </div>
            </Popover>
          </div>
          {appliedFilters?.length > 0 && (
            <div className='applied-filters__wrap'>
              {appliedFilters.map((f, i) => (
                <span key={`${f.label}-i`} className='tag'>
                  <Tag onRemove={f.onRemove}>{f.label}</Tag>
                </span>
              ))}
            </div>
          )}
        </Card.Section>
        <div className='table__wrap'>
          <Suspense fallback={<SkeletonTable sort={'created_date'} order={'ascending'} headings={journalTableColumnData} />}>
            <JournalTable dateFilter={filters?.dateFilter} typeFilter={filters?.typeFilter} />
          </Suspense>
        </div>
      </Card>
    </View>
  )
}

export default Journal
